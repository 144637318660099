import { default as _91_46_46_46pageSlug_93bhfGc76fODMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activate5ooftx6HqsMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/activate.vue?macro=true";
import { default as indexxBXmZ4xet8Meta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93bgCBWv3RGvMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newZ6MZCn8qk5Meta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistwtK3QO1wmDMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/watchlist.vue?macro=true";
import { default as faqdYr5GqmpHSMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as indexa8pdfCasHiMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue?macro=true";
import { default as infodAwAJ23NC0Meta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as indexmEuzi6gbWyMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue?macro=true";
import { default as _91showGroupSlug_93RTO82igazEMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programWvTiW32jo4Meta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopEoIdLbkugPMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93JINGNiznV3Meta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as indexV2zemdAM8aMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_93n3KkQzzKRYMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchers9BPl8WASc3Meta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93tipvFhIvRTMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexxHFKxB756UMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93A42yNFRIUjMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasPHpZ6tLGZpMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesDzbN30RadvMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cities.vue?macro=true";
import { default as indexANcXAlMkReMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/index.vue?macro=true";
import { default as indexOvHGOU6akYMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as _91movieSlug_93WlPCDKfjZSMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexrjXLyk7XyYMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93uFwuGHMKOPMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresnMsM50p0SLMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexXaicFHaOQqMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/index.vue?macro=true";
import { default as indexvvryj4XR3HMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93HytHw1frlxMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsHPEjojoAPeMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/persons.vue?macro=true";
import { default as searchJ0Cx1oeKeBMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/search.vue?macro=true";
import { default as vouchersWHYSYmNZvUMeta } from "/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93A42yNFRIUjMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93tipvFhIvRTMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "",
    meta: indexa8pdfCasHiMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93RTO82igazEMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93n3KkQzzKRYMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93A42yNFRIUjMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93tipvFhIvRTMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexa8pdfCasHiMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93RTO82igazEMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93n3KkQzzKRYMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93A42yNFRIUjMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93tipvFhIvRTMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexa8pdfCasHiMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93RTO82igazEMeta?.name,
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93n3KkQzzKRYMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_93WlPCDKfjZSMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93WlPCDKfjZSMeta?.name,
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93WlPCDKfjZSMeta?.name,
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93uFwuGHMKOPMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93uFwuGHMKOPMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93uFwuGHMKOPMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_93HytHw1frlxMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93HytHw1frlxMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93HytHw1frlxMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-08-27_09-34-20_ce07e4a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]